import '../static/fonts/fonts.css';
// import NewsletterSubscribe from './NewsletterSubscribe';
import 'intersection-observer';

import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Flex, Text } from 'rebass';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';

import theme from '../styles/theme';
import Navbar from './Navbar';

interface LayoutProps {
  variant?: 'primary' | 'white';
  showLogo?: boolean;
}

const Wrapper = styled(Flex)`
  min-height: 100vh;
`;

const Container = styled(Flex)`
  max-width: 1280px;
`;

const Footer = styled(Flex)`
  background-color: ${props => props.theme.colors.green};
  min-height: 276px;
`;

const Image = styled(Box)`
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

const ActionText = styled.a`
  margin-top: 10px;
  text-decoration: underline;

  &:hover {
    opacity: 0.5;
  }
`;

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: gill-sans-nova, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const Layout: React.FC<LayoutProps> = ({ children, ...props }) => {
  // const { prismicFooter } = useStaticQuery(graphql`
  //   query {
  //     prismicFooter {
  //       data {
  //         email_address {
  //           text
  //         }
  //         locations {
  //           location {
  //             document {
  //               data {
  //                 text {
  //                   text
  //                 }
  //                 street2 {
  //                   text
  //                 }
  //                 street1 {
  //                   text
  //                 }
  //                 location_name {
  //                   text
  //                 }
  //                 locality {
  //                   text
  //                 }
  //               }
  //             }
  //           }
  //         }
  //         social_media {
  //           item {
  //             document {
  //               data {
  //                 logo {
  //                   url
  //                 }
  //                 link {
  //                   url
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

  // const data = prismicFooter.data;

  return (
    <ThemeProvider theme={theme}>
      <Wrapper flexDirection="column">
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/pil6fen.css" />
        </Helmet>
        {/* <Navbar {...props} /> */}
        <Flex flex={1} flexDirection="column">
          {children}
        </Flex>
        <Footer color="white" alignItems="flex-end" justifyContent="center">
          <Container width={1} flexDirection="column" mx={3} my={[4, 3, 3]}>
            {/* <Flex
              flex={1}
              width={1}
              flexDirection={['column', 'column', 'row']}
              mb={3}
            >
              <Flex width={[1, 1, 1 / 2]} flexWrap="wrap">
                {data.locations &&
                  data.locations.map(({ location: { document } }: any) => {
                    const { data } = document[0];

                    return (
                      <Box key={data.location_name.text} mb={[5, 5, 4]} mr={[4, 4, 5]}>
                        <Text
                          fontFamily="big-caslon-fb"
                          mb={3}
                          fontSize={3}
                          fontWeight={200}
                        >
                          {data.location_name.text}
                        </Text>
                        <Text mb={1}>{data.street1.text}</Text>
                        <Text mb={1}>{data.street2.text}</Text>
                        <Text mb={1}>{data.locality.text}</Text>
                        <Text>{data.text.text}</Text>
                      </Box>
                    );
                  })}
              </Flex>
              <Flex
                width={[1, 1, 1 / 2, 1 / 3]}
                mb={[5, 5, 0]}
                flexDirection="column"
              >
                <Flex flexWrap="wrap">
                  {data.social_media &&
                    data.social_media.map(({ item: { document } }: any) => {
                      const { data } = document[0];

                      return (
                        <a
                          key={data.logo.url}
                          href={data.link.url}
                          target="_blank"
                        >
                          <Image mr={3}>
                            <img
                              width="100%"
                              height="100%"
                              src={data.logo.url}
                            />
                          </Image>
                        </a>
                      );
                    })}
                </Flex>
                <ActionText href={`mailto:${data.email_address.text}`}>
                  {data.email_address.text}
                </ActionText>
              </Flex>
              <Flex
                width={[1, 1, 1 / 2]}
                mb={[4, 4, 2]}
                flexDirection="column"
                fontSize={4}
              >
                <NewsletterSubscribe />
              </Flex>
            </Flex> */}
            <Flex fontSize={1} flexDirection={['column', 'column', 'row']}>
              <Flex mb={[2, 2, 0]}>
                <Text fontWeight={200}>
                  &copy; {new Date().getFullYear()} Attention Capital
                </Text>
              </Flex>
              <Flex
                flex={1}
                justifyContent={['flex-start', 'flex-start', 'flex-end']}
              >
                <Text fontWeight={200}>
                  <Link to="/terms">Terms &amp; Conditions</Link>
                </Text>
                <Text mx={3} fontWeight={200}>
                  |
                </Text>
                <Text fontWeight={200}>
                  <Link to="/privacy">Privacy</Link>
                </Text>
              </Flex>
            </Flex>
          </Container>
        </Footer>
        <GlobalStyle />
      </Wrapper>
    </ThemeProvider>
  );
};

Layout.defaultProps = {
  variant: 'primary',
  showLogo: false,
};

export default Layout;
