import preset from '@rebass/preset';

export const colors = {
  ...preset.colors,
  green: '#026463',
  blue: '#161f2e',

};

const theme = {
  ...preset,
  colors,
};

export default theme;
